var exports = {};
exports = {
  A: {
    D: {
      "1": "Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "33": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X"
    },
    L: {
      "1": "H"
    },
    B: {
      "1": "Y Z a b e f g h i j k l m n o p q c H",
      "33": "C K L G M N O P Q R S T U V W X"
    },
    C: {
      "1": "tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "0 1 2 3 4 5 6 7 8 9 7B qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R 8B 9B"
    },
    M: {
      "1": "c"
    },
    A: {
      "2": "J D E F 6B",
      "33": "A B"
    },
    F: {
      "1": "jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "33": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB"
    },
    K: {
      "1": "d",
      "2": "A B C nB 4B oB"
    },
    E: {
      "1": "G HC IC zB 0B 1B 2B pB 3B JC",
      "2": "KC",
      "33": "I r J D E F A B C K L CC wB DC EC FC GC xB nB oB yB"
    },
    G: {
      "1": "iC jC zB 0B 1B 2B pB 3B",
      "33": "E wB QC 5B RC SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC"
    },
    P: {
      "1": "1C pB 2C 3C 4C",
      "33": "I sC tC uC vC wC xB xC yC zC 0C"
    },
    I: {
      "1": "H",
      "2": "qB I lC mC nC oC 5B",
      "33": "pC qC"
    }
  },
  B: 6,
  C: "::file-selector-button CSS pseudo-element"
};
export default exports;